.inputRangeAudio{
    @media (max-width: 575.98px) {
        display: flex;
            width: 90px;
            
        }
    audio{
        -webkit-appearance: none;
        @media (max-width: 575.98px) {
                margin-right: 4px;
        
            }
    }
input[type=range] {
        background: transparent;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 70%;
        @media (max-width: 575.98px) {
                margin-right: 4px;
        
            }
    }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        // width: 30%;
        height: 7px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 1px 1px 2px $gray-900;
        background: $blue-400;
        border-radius: 4px;
        border: 2px solid $primary;
        
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: 1px 1px 2px $gray-900;
        border: 2px solid $primary;
        height: 15px;
        width: 15px;
        border-radius: 0px;
        background: $blue-400;
        cursor: pointer;
        -webkit-appearance: none;
         margin-top: -6px;
        
    }

    // input[type=range]::-moz-range-track {
    //     width: 100%;
    //     height: 12px;
    //     cursor: pointer;
    //     animate: 0.2s;
    //     box-shadow: 1px 1px 2px #A6A6A6;
    //     background: #FF96AB;
    //     border-radius: 4px;
    //     border: 2px solid #F27B7F;
    // }

    // input[type=range]::-moz-range-thumb {
    //     box-shadow: 1px 1px 2px #A6A6A6;
    //     border: 2px solid #F27B7F;
    //     height: 30px;
    //     width: 30px;
    //     border-radius: 0px;
    //     background: #FF96AB;
    //     cursor: pointer;
    // }

    // input[type=range]::-ms-track {
    //     width: 100%;
    //     height: 12px;
    //     cursor: pointer;
    //     animate: 0.2s;
    //     background: transparent;
    //     border-color: transparent;
    //     color: transparent;
    // }

    // input[type=range]::-ms-fill-lower {
    //     background: #FF96AB;
    //     border: 2px solid #F27B7F;
    //     border-radius: 8px;
    //     box-shadow: 1px 1px 2px #A6A6A6;
    // }

    // input[type=range]::-ms-fill-upper {
    //     background: #FF96AB;
    //     border: 2px solid #F27B7F;
    //     border-radius: 8px;
    //     box-shadow: 1px 1px 2px #A6A6A6;
    // }

    // input[type=range]::-ms-thumb {
    //     margin-top: 1px;
    //     box-shadow: 1px 1px 2px #A6A6A6;
    //     border: 2px solid #F27B7F;
    //     height: 30px;
    //     width: 30px;
    //     border-radius: 0px;
    //     background: #FF96AB;
    //     cursor: pointer;
    // }

    // input[type=range]:focus::-ms-fill-lower {
    //     background: #FF96AB;
    // }

    // input[type=range]:focus::-ms-fill-upper {
    //     background: #FF96AB;
    // }
}
