@import "./icons.scss";

@import "./bootstrap.scss";
@import "./app.scss";

// RTL
// @import "./rtl/general-rtl";
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/text-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/pages-rtl";

@import "./custom.scss";

@import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import '/node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "/node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import '/node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '/node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import "/node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-react-kanban/styles/material.css";
@import "/node_modules/@syncfusion/ej2-icons/styles/material.css";
