.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // background-color: $white;
  opacity: 0.7;
  z-index: 999;
  .status {
    position: "absolute";
    left: "50%";
    top: "50%";
    transform: "translate(-50%; -50%)";
    .sr-only {
      display: none !important;
    }
  }
}
.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
  }
}

.alert-dismiss-custom {
  .close {
    box-sizing: content-box;
    width: 0.6em;
    height: 0.6em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("../images/close.svg") center/.6em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
}

.user-chat-topbar .topbar-bookmark .close {
  padding: 12px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-hue:hover {
  filter: hue-rotate(245deg);
  transition: filter 0.2s;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e6ebf5;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #cfd4dd;
    outline: 0;
    box-shadow: none;
  }
}

.campaign {
  max-height: calc(100vh - 20px);
  padding: 80px 0;

  .header {
    width: fit-content;
    display: flex;
    gap: 0.5rem;
    background-color: rgba($primary, 0.23);
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    h4 {
      margin: 0 !important;
    }
    span {
      font-size: 11px;
    }
  }
}

.filters {
  background-color: rgba(var(--bs-light-rgb), 0.6) !important;
  border: 1px solid rgba(var(--bs-secondary-rgb), 0.23) !important;
  margin: 1rem 0;
  padding: 0.25rem 1rem;
  gap: 1.75erm;
  justify-content: space-evenly;
  flex-wrap: wrap;

  li {
    background: none;
    font-size: 0.8rem;
    border: none;
    padding: 0.5rem 0.25rem;

    div {
      line-height: 30px;
    }

    input,
    select {
      border: 2px solid rgba(var(--bs-secondary-rgb), 0.2) !important;
    }

    input {
      width: 105px;
    }

    select {
      width: 50px;
    }
  }
}

.leads {
  border: 1px solid rgba(var(--bs-secondary-rgb), 0.33) !important;
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  min-height: 375px;

  table {
    // --bs-table-bg: rgba(var(--bs-light-rgb), 1) !important;
    --bs-table-bg: transparent !important;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-secondary-rgb);
    --bs-table-striped-bg: rgba(var(--bs-secondary-rgb), 0.15) !important;
    --bs-table-active-color: var(--bs-secondary-rgb);
    --bs-table-active-bg: var(--bs-secondary-rgb);
    --bs-table-hover-color: var(--bs-secondary-rgb);
    --bs-table-hover-bg: rgba(var(--bs-primary-rgb), 0.23) !important;
    color: var(--bs-secondary-rgb);
    font-size: 0.8rem;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
    padding: 0;

    background-color: rgba(var(--bs-light-rgb), 0.5) !important;

    thead,
    thead tr,
    thead th {
      background-color: rgba(var(--bs-primary-rgb), 0.11) !important;
    }

    th {
      padding: 1.25rem 0;
      color: rgba(var(--bs-dark-rgb), 0.9) !important;
      border-bottom: 3px solid rgba(var(--bs-secondary-rgb), 0.23) !important;

      div {
        min-width: max-content;
        overflow: hidden;
        height: 36px;
        line-height: 34px;
        padding: 0.25rem 0.5rem;
      }

      & + th {
        div {
          border-left: 1px solid rgba(var(--bs-primary-rgb), 1) !important;
        }
      }
    }

    tbody tr {
      border-top: 1px solid rgba(var(--bs-secondary-rgb), 0.13) !important;
      border-left: 1px solid rgba(var(--bs-secondary-rgb), 0.13) !important;
      border-right: 1px solid rgba(var(--bs-secondary-rgb), 0.13) !important;

      &:hover {
        border-left: 1px solid rgba(var(--bs-primary-rgb), 0.5) !important;
        border-right: 1px solid rgba(var(--bs-primary-rgb), 0.5) !important;
      }
    }

    td {
      padding: 0.75rem 0;

      div {
        width: auto !important;
        max-width: 300px !important;
        min-width: 34px !important;
        line-height: 34px;
        padding: 0.25rem 0.5rem;
      }

      & + td {
        div {
          border-left: 1px solid rgba(var(--bs-primary-rgb), 0.5) !important;
        }
      }

      select {
        border: 1px solid rgba(var(--bs-secondary-rgb), 0.33) !important;
        transition: border-color 0.2s;
        &:hover {
          border-color: rgba(var(--bs-primary-rgb), 1) !important;
        }
      }
    }
  }
}

.pagination {
  nav {
    // border: 2px solid rgba(var(--bs-secondary-rgb), 0.23) !important;
    border-radius: 4px;
    background-color: #fff !important;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .page-link {
    border: 2px solid rgba(var(--bs-secondary-rgb), 0.23) !important;
    transition: border-color 0.2s;
    padding: 0 0.5rem;

    &:hover {
      border-color: rgba(var(--bs-primary-rgb), 0.5) !important;
    }

    .sr-only {
      margin-left: 0.35rem;
    }

    &:has(select) {
      padding: 0;
    }
  }
}

.notification {
  position: relative;
  display: flex;
  align-items: center;

  i {
    font-size: 22px;
    color: rgba($orange, 1);
    animation: blink 0.5s infinite ease-in-out;
  }

  .counter {
    position: absolute;
    top: 0;
    right: -4px;
    z-index: 1;
    font-size: 0.5rem;
    padding: 0.1rem 0.18rem;
    border-radius: 2px;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
}

body[data-layout-mode="dark"] {
}

.profile-groups {
  ul {
    li a:hover {
      background-color: rgba(var(--bs-secondary-rgb), 0.23) !important;
    }
  }
}

.modal-item-preview-backdrop {
  opacity: 0.85 !important;
}

.modal-item-preview {
  background-color: transparent !important;

  & > *,
  .modal-header {
    border: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    background-color: transparent !important;
  }
}

.tag-list {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap !important;
  padding: 0;
  margin: 0;

  li {
    padding: 0;
    margin: 0;
    border-radius: 4px;
    color: $white;
    height: 15px;
    max-height: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid transparent !important;

    .badge {
      font-weight: 400;
      line-height: normal;
    }

    button {
      border: none;
      color: $secondary;
      overflow: hidden;
      width: 25px;
      height: 100%;
      font-size: 0.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: font-size 0.2s, color 0.2s;

      &:hover {
        // color: $white;
        font-size: 1.2rem;
        filter: saturate(400%);
      }
    }

    &:has(button) {
      overflow: hidden;
      height: 25px;
      max-height: 25px;

      .badge {
        border-radius: 0;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
      }
    }
  }

  .active {
    border: 0.5px solid rgba($light, 0.65) !important;
  }

  li,
  button {
    background: transparent;
  }
}

.cursor-alias {
  cursor: alias;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-auto {
  cursor: auto;
}
.cursor-cell {
  cursor: cell;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-copy {
  cursor: copy;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-default {
  cursor: default;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.cursor-help {
  cursor: help;
}
.cursor-move {
  cursor: move;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-text {
  cursor: text;
}
.cursor-w-resize {
  cursor: w-resize;
}
.cursor-wait {
  cursor: wait;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
