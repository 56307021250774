//
// BoxChart.scss
//

.chart-box {
  max-width: 100%;
  height: 130px;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #495057;
  font-weight: 600;
  overflow: hidden;
  margin: 0;

  .header {
    width: 100% !important;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-bottom: 1px solid transparent;

    i {
      font-size: 1.2rem !important;
    }

    span {
      font-size: 0.85rem !important;
    }
  }

  .container {
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
  }
}
body[data-layout-mode="dark"] {
}
