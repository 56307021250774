@use "sass:color";
body[data-layout-mode="dark"] {
  background-color: color.adjust($gray-dark-200, $lightness: 2%);
  color: $gray-dark-500;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gray-dark-600;
  }
}
