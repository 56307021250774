//
// popover.scss
//

.popover-box {
  .popover-inner {
    width: 274px;
    // overflow: hidden;

    .popover-footer {
      padding: 1rem 1rem;
      color: #495057;
      display: flex;

      button {
        width: 100%;
      }
    }

    .dropdown-menu {
      max-width: 243px;

      .dropdown-item {
        .avatar-xs {
          min-width: 28px;
          min-height: 28px;
          max-width: 28px;
          max-height: 28px;
        }

        &:hover {
          color: $white;
          background-color: rgba(var(--bs-primary-rgb), 1);
        }
      }

      .dropdown-item.selected {
        &:hover {
          color: $white;
          background-color: rgba(var(--bs-danger-rgb), 1);
        }
      }
    }
  }
}
